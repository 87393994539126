<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'InvoiceLogList' }">
              登錄發票列表
            </b-breadcrumb-item>

            <b-breadcrumb-item active>{{ isReadOnly ? '登錄發票查看' :'登錄發票編輯' }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <h4 class="font-weight-bold">{{ isReadOnly ? '登錄發票查看' :'登錄發票編輯' }}</h4>

        <div class="row">
          <div class="col-12 col-xl-8 mt-3">
            <InvoiceLogForm
              ref="invoiceLogForm"
              :invoice-log="invoiceLog"
              :formReadonly="isReadOnly"
              :status-readonly="true"
            >
            </InvoiceLogForm>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-3">
          <b-button class="mr-3" @click="goList" variant="outline-danger">
            返回
          </b-button>
          <b-button @click="handleSubmit" variant="success">儲存</b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import invoiceLogApi from "@/apis/invoice-log";
import InvoiceLogForm from "@/components/Page/Dashboard/InvoiceLog/InvoiceLogForm";
import { mapGetters } from "vuex";

export default {
  components: { InvoiceLogForm },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    isReadOnly() {
      return this.$route.name === "InvoiceLogView";
    },
  },
  data() {
    return {
      showLoading: false,
      invoiceLog: {
        no: null,
        type: null,
        issued_at: null,
        random: null,
        customer: {},
      },
    };
  },
  mounted() {
    this.fetchInvoiceLog()
  },
  methods: {
    async fetchInvoiceLog() {
      this.showLoading = true
      try {
        const { data } = await invoiceLogApi.getInvoiceLog(this.$route.params.invoiceLogId)
        this.invoiceLog = data.data
      } catch (error) {
        console.error(error)
      }
      this.showLoading = false
    },
    goList() {
      this.$router.push({ name: "InvoiceLogList", query: this.routeQuery });
    },
    async handleSubmit() {
      const result = await this.$refs.invoiceLogForm.validate()
      if (!result) {
        return
      }

      this.showLoading = true
      try {
        await invoiceLogApi.updateInvoiceLog(this.$route.params.invoiceLogId, this.invoiceLog)
        this.$swal.fire({ type: 'success', title: '修改成功' })
        this.goList()
      } catch (error) {
        this.$swal.fire({ type: 'error', title: '修改失敗' })
        console.error(error)
      }
      this.showLoading = false
    },
  },
};
</script>
